<template>
    <Page
        :title="title"
        icon="mdi-settings"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
    >
        <file-upload label="Page Numbers PDF file" file-type="page numbers" />
        <file-upload label="Test Multi File upload" file-type="test multi" multiple />
        <file-upload label="Test single File upload" file-type="test single" />
    </Page>
</template>
<script>
import FileUpload from '@/components/form/fields/fileUpload/FileUploadField';

export default {
    components: { FileUpload },
    data() {
        return {
            title: 'Configuration Page',
            loadingMessage: 'Retrieving results.',
            isShowLoading: false,
            errorMessage: '',
            isShowError: false,
        };
    },
};
</script>
